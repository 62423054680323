import React, { useState } from "react";
import { Button, Card, Row, Space, List } from "antd";
import { FormattedMessage } from "react-intl";
import {
  CloudUploadOutlined,
  PlusOutlined,
  FileZipOutlined,
  DeleteOutlined,
  LinkOutlined
} from "@ant-design/icons";
import UploadCreativeDrawer from "../components/UploadCreativeDrawer";
import AddCreativeDrawer from "./AddCreativeDrawer";
import { unionWith, eqBy, prop } from "ramda";
import VideoElement from "./VideoElement";


const { REACT_APP_BUCKET_URL = 'https://ccfr-cms-new-prod-frontend-ng.s3.eu-west-3.amazonaws.com' } = process.env;

function CreativeCard({ creatives, setCreatives }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const unSelectedCreative = (id) => {
    setCreatives(creatives.filter((creative) => creative.id !== id));
  };

  return (
    <Card
      title={<FormattedMessage id="Creations" />}
      size="small"
      style={{ marginTop: "10px" }}
      extra={
        <Row justify="end">
          <Space>
            <Button type="dashed" onClick={() => setDrawerOpen("upload")}>
              <CloudUploadOutlined />
            </Button>
            <Button type="dashed" onClick={() => setDrawerOpen("add")}>
              <PlusOutlined />
            </Button>
          </Space>
        </Row>
      }
    >
      <UploadCreativeDrawer
        open={drawerOpen === "upload"}
        onClose={() => {
          setDrawerOpen(false);
        }}
        onSave={(creative) => setCreatives([...creatives, ...creative])}
      />
      <AddCreativeDrawer
        onClose={() => {
          setDrawerOpen(false);
        }}
        selectedCreatives={creatives}
        setSelectedCreatives={setCreatives}
        open={drawerOpen === "add"}
        onSave={(newCreatives) => {
          const creativesWithOutDuplicates = unionWith(
            eqBy(prop("id")),
            newCreatives,
            creatives
          );
          setCreatives(creativesWithOutDuplicates);
          setDrawerOpen(false);
        }}
      />

      <List
        itemLayout="vertical"
        size="large"
        dataSource={creatives}
        renderItem={(creative) => {
          const { bucketKey = '', url = '' } = creative;

          const isVideo = bucketKey.match(
            /^.*.(mp4|webm|mov|wmv)$/i
          );
          const isImage = bucketKey.match(
            /^.*.(png|gif|jpeg|jpg)$/i
          );
          const isHTML = bucketKey.match(/^.*.(html|zip)$/i);
          const isLink = !bucketKey && url

          return (
            <Card
              size="small"
              title={creative.title}
              style={{ marginBottom: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {isVideo && (
                  <VideoElement
                    height="120px"
                    url={`${REACT_APP_BUCKET_URL}/${creative["bucketKey"]}`}
                  />
                )}

                {isImage && (
                  <img
                    style={{ height: "120px", objectFit: "cover" }}
                    alt="logo"
                    src={`${REACT_APP_BUCKET_URL}/${creative["bucketKey"]}`}
                  />
                )}

                {isHTML && (
                  <FileZipOutlined
                    style={{ fontSize: "48px", margin: "26px 0" }}
                  />
                )}

                {isLink && (
                  <LinkOutlined
                    style={{ fontSize: "48px", margin: "26px 0" }}
                  />
                )}
                <Button
                  onClick={() => {
                    unSelectedCreative(creative.id);
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            </Card>
          );
        }}
      />
    </Card>
  );
}

export default CreativeCard;
