import React, { useEffect, useState } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Col, Input, Modal, List, Select, Row, message } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import moment from "moment";

import { useFetchWithMsal } from "../hooks";
import UploadCreative from "../components/UploadCreative";
import CreativeListItem from "../components/CreativeListItem";
import { config } from "../config";

const sortOptions = [
  {
    id: "TitleAscend",
    value: "title-asc",
    sort: "title",
    dir: "asc",
  },
  {
    id: "TitleDescend",
    value: "title-desc",
    sort: "title",
    dir: "desc",
  },
  {
    id: "ClientDescend",
    value: "client-desc",
    sort: "client",
    dir: "desc",
  },
  {
    id: "ClientAscend",
    value: "client-asc",
    sort: "client",
    disc: "asc",
  },
  {
    id: "AnnonceurDescend",
    value: "announcer-desc",
    sort: "annonceur",
    dir: "desc",
  },
  {
    id: "AnnonceurAscend",
    value: "announcer-asc",
    sort: "annonceur",
    dir: "asc",
  },
  {
    id: "UploadedDescend",
    value: "uploaded-desc",
    sort: "createdAt",
    dir: "desc",
  },
  {
    id: "UploadedAscend",
    value: "uploaded-asc",
    sort: "createdAt",
    dir: "asc",
  },
];

function Creatives({ intl }) {
  const { fetch, data } = useFetchWithMsal();
  const [creatives, setCreatives] = useState([]);
  const [total, setTotal] = useState(6);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [creativeToDelete, setCreativeToDelete] = useState({});
  const [file, setFile] = useState(null);
  const [pageSize] = useState(8);
  const [sort, setSort] = useState({ sort: "createdAt", dir: "desc" });

  useEffect(() => {
    let isMounted = true;

    async function getCreatives() {
      try {
        let url = `creatives?page=${page}&pagesize=${pageSize}&sort=${sort.sort}&dir=${sort.dir}`;

        if (search) {
          url = `${url}&q=${search}`;
        }
        await fetch(url);
      } catch (e) {
        console.log("error: ", e);
        message.error(
          "Une erreur s'est produite lors de la récupération des créations"
        );
      }
    }

    if (isMounted) {
      getCreatives();
    }

    return () => {
      isMounted = false;
    };
  }, [page, search, total, pageSize, sort, fetch]);

  useEffect(() => {
    if (data) {
      const { docs: creatives = [], total } = data;

      setCreatives(creatives);
      setTotal(total);
    }
  }, [data]);

  const onSearch = (value) => {
    if (value) {
      setPage(1);
      return setSearch(value.toLowerCase());
    }

    setSearch("");
  };

  const changeFilter = (value) => {
    const sortOption = sortOptions.find(
      (sortOption) => sortOption.value === value
    );

    if (!sortOption) {
      return;
    }

    setPage(1);
    setSort({ sort: sortOption.sort, dir: sortOption.dir });
  };

  const deleteCreativeConfirm = async () => {
    try {
      await fetch(`creatives/${creativeToDelete.id}`, {
        method: "DELETE",
      });
      setCreatives((creatives) =>
        creatives.filter((creative) => creative.id !== creativeToDelete.id)
      );

      setCreativeToDelete({});
    } catch (error) {
      message.error(
        "Une erreur s'est produite lors de la récupération des créations"
      );
    }
  };

  const deleteCreativeCancel = () => setCreativeToDelete({});

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <Row
        type="flex"
        justify="flex-start"
        style={{
          display: "flex",
          margin: "6px",
        }}
      >
        <Input.Search
          placeholder={intl.formatMessage({ id: "Search" })}
          onSearch={onSearch}
          allowClear
          enterButton
          style={{ flex: 1, maxWidth: "230px", marginRight: "6px" }}
        />
        <Select
          placeholder="Select filter"
          style={{ flex: 1, maxWidth: "230px", marginLeft: "6px" }}
          onChange={changeFilter}
          defaultValue="uploaded-desc"
        >
          {sortOptions.map((option) => (
            <Select.Option value={option.value}>
              <FormattedMessage id={option.id} />
            </Select.Option>
          ))}
        </Select>
      </Row>
      <Row>
        <Col span={14}>
          <Row
            type="flex"
            style={{
              marginTop: "24px",
              display: "block",
              width: "100%",
              textAlign: "center",
              padding: "0 24px 0 0",
            }}
          >
            <List
              grid
              gutter={10}
              column={6}
              pagination={{
                current: page,
                defaultPageSize: pageSize,
                onChange: (page) => setPage(page),
                total: total,
              }}
              dataSource={creatives}
              renderItem={(item) => (
                <CreativeListItem
                  {...item}
                  onDelete={(id) => {
                    const creative = creatives.find(
                      (creative) => creative.id === id
                    );
                    setCreativeToDelete(creative);
                  }}
                />
              )}
            />
          </Row>
        </Col>
        <Col span={10} style={{ marginTop: "24px" }}>
          <UploadCreative
            onSave={(datas) => {
              const creativesSorts = [...datas, ...creatives].sort(
                (a, b) => moment(b.uploaded) - moment(a.uploaded)
              );
              setCreatives(creativesSorts);
              setFile(null);
            }}
            action={`${config.api}/creatives/upload`}
            file={file}
            setFile={setFile}
          />
        </Col>
      </Row>
      {creativeToDelete.id && (
        <Modal
          title={<FormattedMessage id="DeleteCreative" />}
          visible={true}
          onOk={deleteCreativeConfirm}
          onCancel={deleteCreativeCancel}
        >
          <p>
            <FormattedMessage
              id="DeleteMsg"
              values={{ title: creativeToDelete.title }}
            />
          </p>
        </Modal>
      )}
    </MsalAuthenticationTemplate>
  );
}

export default injectIntl(Creatives);
