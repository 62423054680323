import React from "react";
import { Menu, Layout } from "antd";
import {
  DashboardOutlined,
  PictureOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { FormattedMessage } from "react-intl";
import { LanguageProvider } from "./hooks";
import ContentPlans from "./pages/ContentPlans";
import ContentPlan from "./pages/ContentPlan";
import Creatives from "./pages/Creatives";
import Play from "./pages/Play";
import Header from "./components/Header";

import "./styles/App.css";

function App({ instance }) {
  return (
    <Router>
      <MsalProvider instance={instance}>
        <LanguageProvider>
          <Layout style={{ minHeight: "100%" }}>
            <Layout.Sider theme="light" width={256}>
              <div className="logo-light" />
              <Menu
                defaultSelectedKeys={[window.location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/content-plans" icon={<DashboardOutlined />}>
                  <Link to="/content-plans">
                    <FormattedMessage id="Campaigns" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/creatives" icon={<PictureOutlined />}>
                  <Link to="/creatives">
                    <FormattedMessage id="Creatives" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="/import" icon={<CloudUploadOutlined />}>
                  <Link to="/import">
                    <FormattedMessage id="ImportPlay" />
                  </Link>
                </Menu.Item>
              </Menu>
            </Layout.Sider>
            <Layout style={{ minHeight: "100%" }}>
              <Header />
              <Layout.Content
                style={{
                  backgroundColor: "#ffffff",
                  padding: 24,
                  margin: "24px 16px",
                }}
              >
                <Switch>
                  <Route path="/content-plans/:id" component={ContentPlan} />
                  <Route path="/creatives" component={Creatives} />
                  <Route path="/import" component={Play} />
                  <Route path="/" component={ContentPlans} />
                </Switch>
              </Layout.Content>
            </Layout>
          </Layout>
        </LanguageProvider>
      </MsalProvider>
    </Router>
  );
}

export default App;
