import React, { useState, useEffect } from "react";
import { Input, message, Row, Table, Select } from "antd";
import moment from "moment";
import { injectIntl, FormattedMessage } from "react-intl";
import { useFetchWithMsal } from "../hooks";

const columns = [
  {
    title: <FormattedMessage id="Date" />,
    dataIndex: "createdAt",
    width: 80,
    render: (date) => `${moment(date).format("YYYY-MM-DD")}`,
    key: "createdAt",
  },
  {
    title: <FormattedMessage id="Name" />,
    dataIndex: "filename",
    ellipsis: true,
    width: 150,
    key: "filename",
  },
  {
    title: <FormattedMessage id="User" />,
    dataIndex: "createdBy",
    width: 80,
    render: (createdBy) => createdBy || "Unauthenticated",
    key: "createdBy",
  },
  {
    title: <FormattedMessage id="FileType" />,
    dataIndex: "type",
    width: 80,
    key: "type",
  },
  {
    title: <FormattedMessage id="status" />,
    dataIndex: "status",
    width: 80,
    render: (status) => status ? <FormattedMessage id={status} /> : <FormattedMessage id="SUCCEED" /> ,
    key: "status",
  },
];

function PlayFiles({ intl, loading }) {
  const { fetch, data } = useFetchWithMsal();
  const [savedFiles, setSavedFiles] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    async function getSavedFiles() {
      let url = `play/files?page=${1}&pagesize=10000`;

      if (search) {
        url = `${url}&q=${search}`;
      }

      try {
        await fetch(url);
      } catch (e) {
        message.error(
          "Une erreur s'est produite lors de la récupération des fichiers Play"
        );
      }
    }

    getSavedFiles();
  }, [search, loading, fetch]);

  useEffect(() => {
    if (!data) return;

    const { docs: files = [] } = data;

    setSavedFiles(
      files
        .map((file, index) => ({ ...file, key: index }))
        .sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
    );
  }, [data]);

  function onSearch(value) {
    if (value) {
      return setSearch(`filename:${value}*`);
    }

    setSearch("");
  }

  const changeFilter = (value) => {
    const [name, order] = value.split("-");
    const isAsc = order === "asc" ? true : false;

    const orderCreatives = savedFiles.sort((a, b) => {
      if (name === "createdAt") {
        return isAsc
          ? moment(a.createdAt) - moment(b.createdAt)
          : moment(b.createdAt) - moment(a.createdAt);
      }

      if (a[name] && b[name])
        return isAsc
          ? a[name].localeCompare(b[name])
          : b[name].localeCompare(a[name]);

      return 1;
    });

    setSavedFiles([...orderCreatives]);
  };

  return (
    !loading && (
      <>
        <Row
          type="flex"
          justify="flex-start"
          style={{
            display: "flex",
            margin: "6px",
          }}
        >
          <Input.Search
            placeholder={intl.formatMessage({ id: "Search" })}
            onSearch={onSearch}
            allowClear
            enterButton
            style={{ flex: 1, maxWidth: "230px", marginRight: "6px" }}
          />
          <Select
            placeholder="Select filter"
            style={{ flex: 1, maxWidth: "230px", marginLeft: "6px" }}
            onChange={changeFilter}
            defaultValue="createdAt-desc"
          >
            <Select.Option value="createdAt-asc">
              <FormattedMessage id="DateAscend" />
            </Select.Option>
            <Select.Option value="createdAt-desc">
              <FormattedMessage id="DateDescend" />
            </Select.Option>
            <Select.Option value="filename-asc">
              <FormattedMessage id="NameAscend" />
            </Select.Option>
            <Select.Option value="filename-desc">
              <FormattedMessage id="NameDescend" />
            </Select.Option>
            <Select.Option value="createdBy-asc">
              <FormattedMessage id="UserAscend" />
            </Select.Option>
            <Select.Option value="createdBy-desc">
              <FormattedMessage id="UserDescend" />
            </Select.Option>
            <Select.Option value="type-asc">
              <FormattedMessage id="FiletypeAscend" />
            </Select.Option>
            <Select.Option value="type-desc">
              <FormattedMessage id="FiletypeDescend" />
            </Select.Option>
          </Select>
        </Row>
        <Row>
          <Table
            size="small"
            style={{ width: "95%", marginTop: "70px" }}
            bordered
            loading={loading}
            columns={columns}
            dataSource={savedFiles}
            pagination={true}
          />
        </Row>
      </>
    )
  );
}

export default injectIntl(PlayFiles);
