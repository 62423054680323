import React, { useState } from "react";
import { Button, Drawer, Row } from "antd";
import UploadCreative from "./UploadCreative";

import { config } from '../config';

function UploadCreativeDrawer({ open, onClose, onSave }) {
  const [files, setFiles] = useState([]);

  return (
    <Drawer
      title="Upload creative"
      placement="right"
      closable
      width="460px"
      mask={false}
      visible={open}
      onClose={() => {
        onClose();
      }}
      footer={
        <Row justify="end">
          <Button
            type="primary"
            onClick={() => {
              setFiles([]);
              onClose();
            }}
          >
            Done
          </Button>
        </Row>
      }
    >
      <UploadCreative
        files={files}
        setFiles={setFiles}
        onSave={onSave}
        action={`${config.api}/creatives/upload`}
      />
    </Drawer>
  );
}

export default UploadCreativeDrawer;
