import React, { createContext, useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";

import frFR from "antd/lib/locale/fr_FR";
import enUs from "antd/lib/locale/en_US";

import en from "../intl/en";
import fr from "../intl/fr";

const initState = {
  locale: "fr",
  defaultLocale: "fr",
  messages: fr,
  antIntl: frFR,
};

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [intl, setIntl] = useState(initState);
  const { locale, defaultLocale, messages, antIntl } = intl;

  useEffect(() => {
    if (locale === "fr") {
      setIntl((s) => ({ ...s, locale: "fr", messages: fr, antIntl: frFR }));
    }

    if (locale === "en") {
      setIntl((s) => ({ ...s, locale: "en", messages: en, antIntl: enUs }));
    }
  }, [locale]);

  return (
    <LanguageContext.Provider value={{ intl, setIntl }}>
      <ConfigProvider locale={antIntl}>
        <IntlProvider
          messages={messages}
          locale={locale}
          defaultLocale={defaultLocale}
        >
          {children}
        </IntlProvider>
      </ConfigProvider>
    </LanguageContext.Provider>
  );
};
