import React, { useEffect, useState } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { update } from "ramda";
import ContentPlanActions from "../components/ContentPlanActions";
import ContentPlanDetail from "../components/ContentPlanDetail";
import ScheduleDrawer from "../components/ScheduleDrawer";
import Schedules from "../components/Schedules";
import { useFetchWithMsal } from "../hooks";
import moment from "moment";

const defaultSchedule = {
  creatives: [],
  timeSchedule: {
    dateRangeType: "LENGTH_OF_CAMPAIGN",
    daysOfWeekType: "ALL_DAYS",
    timeRangeType: "ALL_TIME",
    endDate: null,
    endTime: null,
    startDate: null,
    startTime: null,
  },
  locationSchedule: {
    locationScheduleType: "ALL_LOCATIONS",
    selectedLocations: [],
  },
};

const ContentPlan = () => {
  const { id } = useParams();
  const { fetch, data } = useFetchWithMsal();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [contentPlan, setContentPlan] = useState();
  const [schedules, setSchedules] = useState([]);
  const [editingSchedule, setEditingSchedule] = useState(defaultSchedule);
  const [edited, setEdited] = useState(false);
  const [draggable, setDraggable] = useState(false);

  useEffect(() => {
    async function getContentPlan(id) {
      try {
        const url = `content-plans/${id}`;
        await fetch(url);
      } catch (error) {
        console.log(error);
        message.error(
          "Une erreur s'est produite lors de la récupération du plan de contenu"
        );
      }
    }

    getContentPlan(id);
  }, [id, fetch]);

  useEffect(() => {
    if (!data) return;

    setContentPlan({
      ...data,
      version: data.version || 1,
    });

    setSchedules(data.schedules.filter((schedules) => schedules.timeSchedule));
  }, [data]);

  async function onSave() {
    try {
      const headers = {
        "Content-Type": "application/json",
      };

      setContentPlan({
        ...contentPlan,
        version: contentPlan.version + 1,
        status: "submitted",
        schedules,
      });

      await fetch(`content-plans/${contentPlan._id}`, {
        method: "put",
        body: JSON.stringify({
          ...contentPlan,
          version: contentPlan.version + 1,
          status: "submitted",
          schedules,
        }),
        headers,
      });
      message.success("Contenu enregistré avec succès");
      return setEdited(false);
    } catch (error) {
      message.error("Impossible d'enregistrer le contenu.");
      console.log(error);
    }
  }

  function onCityPortalChange(value) {
    setContentPlan({ ...contentPlan, cityportal: !!value });
    setEdited(true);
  }

  function isValidDate(date) {
    return (
      typeof date === "string" || (date instanceof moment && date.isValid())
    );
  }

  function onDateChange(date, type) {
    if (type === "startDate") {
      setContentPlan({
        ...contentPlan,
        ...(isValidDate(date) && { startDate: date }),
      });

      return setEdited(true);
    }

    setContentPlan({
      ...contentPlan,
      ...(isValidDate(date) && { endDate: date }),
    });
    setEdited(true);
  }

  function onEditSchedule(schedule) {
    setEditingSchedule(schedule);
    setDrawerOpen(true);
  }

  function setStatus(status) {
    return function () {
      setContentPlan({ ...contentPlan, status });
      setDraggable(true);
    };
  }

  if (!contentPlan) {
    return <></>;
  }
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <ContentPlanDetail
        {...contentPlan}
        onCityPortalChange={onCityPortalChange}
        onDateChange={onDateChange}
      />
      <Schedules
        schedules={schedules}
        setSchedules={setSchedules}
        edited={draggable}
        status={contentPlan.status}
        cityportal={contentPlan.cityportal}
        onEditSchedule={onEditSchedule}
        deleteSchedule={(schedule) => {
          const deleteSchedule = schedules.filter(
            (sc) => sc.createdDateTime !== schedule.createdDateTime
          );

          const newSchedule = deleteSchedule.map((sch) => ({
            ...sch,
            priority:
              sch.priority > schedule.priority
                ? sch.priority - 1
                : sch.priority,
          }));

          setSchedules(newSchedule);
        }}
      />
      <ContentPlanActions
        onAdd={() => {
          setEditingSchedule(defaultSchedule);
          setDrawerOpen(true);
        }}
        onEdit={setStatus("draft")}
        status={contentPlan.status}
        edited={edited}
        onSave={onSave}
        cityportal={contentPlan.cityportal}
      />

      <ScheduleDrawer
        {...contentPlan}
        tags={contentPlan.tags}
        editingSchedule={editingSchedule}
        setEditingSchedule={setEditingSchedule}
        onClose={() => setDrawerOpen(false)}
        getScheduleAdd={(schedule) => {
          const newSchedules = schedules.map((sch) => {
            return { ...sch, priority: sch.priority + 1 };
          });

          setSchedules([...newSchedules, { ...schedule, priority: 1 }]);
          setEdited(true);
        }}
        getScheduleEdit={(schedule) => {
          const scheduleIndex = schedules.findIndex(
            (s) => s.priority === schedule.priority
          );
          if (scheduleIndex === -1) {
            return;
          }

          setSchedules(update(scheduleIndex, schedule, schedules));
          setEdited(true);
        }}
        open={drawerOpen}
      />
    </MsalAuthenticationTemplate>
  );
};

export default ContentPlan;
