import React from "react";
import { Card, Image, List, Row, Spin } from "antd";
import { DeleteOutlined, FileZipOutlined, LinkOutlined } from "@ant-design/icons";

import VideoElement from "./VideoElement";

const { REACT_APP_BUCKET_URL = 'https://ccfr-cms-new-prod-frontend-ng.s3.eu-west-3.amazonaws.com' } = process.env;

function CreativeListItem({ onDelete, id, bucketKey = '', title, url = ''}) {
  const assetUrl = `${REACT_APP_BUCKET_URL}/${bucketKey}`;

  const isVideo = bucketKey.match(
    /^.*.(mp4|webm|mov|wmv)$/i
  );
  const isImage = bucketKey.match(
    /^.*.(png|gif|jpeg|jpg)$/i
  );
  const isHTML = bucketKey.match(/^.*.(html|zip)$/i);
  const isLink = !bucketKey && url

  function getElement(url) {
    if (isVideo) {
      return <VideoElement height="360px" url={url} />;
    } else if (isImage) {
      return (
        <Image
          style={{ height: "360px", objectFit: "cover" }}
          alt="creative preview"
          src={url}
        />
      );
    } else if (isHTML) {
      return <FileZipOutlined style={{ fontSize: "120px", margin: "120px 0", padding: "0 55px" }} />;
    } else if (isLink) {
      return <LinkOutlined style={{ fontSize: "120px", margin: "120px 0", padding: "0 55px" }} />;
    }

    return <Row justify="center" align="middle" style={{ height: "360px", width: "230px" }}><Spin size="large" /></Row>;
  }

  return (
    <List.Item>
      <Card
        style={{ maxWidth: "230px", margin: "0 6px" }}
        hoverable
        actions={[<DeleteOutlined key="delete" onClick={() => onDelete(id)} />]}
        cover={getElement(assetUrl)}
      >
        <Card.Meta
          style={{ height: "24px" }}
          title={
            <Row justify="start">
              <p>{title}</p>
            </Row>
          }
        />
      </Card>
    </List.Item>
  );
}

export default CreativeListItem;
