import { Button, Row, Space } from "antd";
import { SaveOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

function ContentPlanActions({ edited = false, onAdd, onSave, onEdit, status, cityportal}) {
  return (
    <Row style={{ marginTop: "16px" }} justify="end">
      <Space>
        <Button
          disabled={status !== "draft" && !edited}
          type="dashed"
          icon={<SaveOutlined />}
          onClick={onSave}
        >
          {" "}
          <FormattedMessage id="Save" />
        </Button>
        <Button
          disabled={status === "draft"}
          type="dashed"
          icon={<EditOutlined />}
          onClick={onEdit}
        >
          {" "}
          <FormattedMessage id="Edit" />
        </Button>
        <Button
          disabled={status !== "draft" || cityportal === true}
          type="dashed"
          icon={<PlusOutlined />}
          onClick={onAdd}
        >
          {" "}
          <FormattedMessage id="Add" />
        </Button>
      </Space>
    </Row>
  );
}

export default ContentPlanActions;
