import { useState, useCallback } from "react";
import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { config } from "../config";

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */
export const useFetchWithMsal = (msalRequest) => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const { result, error: msalError } = useMsalAuthentication(
    InteractionType.Popup,
    {
      ...msalRequest,
      account: instance.getActiveAccount(),
      redirectUri: "/redirect",
    }
  );

  /**
   * Execute a fetch request with the given options
   * @param {string} method: GET, POST, PUT, DELETE
   * @param {String} path: The path to call
   * @param {Object} body: The data to send to the path, if any
   * @returns JSON response
   */
  const execute = async (path, ops = {}) => {
    const { method = "GET", body = null, headers : customHeaders = {}} = ops;

    if (msalError) {
      setError(msalError);
      return;
    }

    if (result) {
      try {
        let response = null;

        const headers = new Headers();
        const bearer = `Bearer ${result.accessToken}`;
        headers.append("Authorization", bearer);

        Object.entries(customHeaders).forEach(([name, value]) => headers.set(name, value))

        const url = `${config.api}/${path}`;
        const options = {
          method: method,
          headers: headers,
          // body: body ? JSON.stringify(body) : null,
          ...(body && { body }),
        };

        setLoading(true);
        const res = await fetch(url, options);

        if (!res.ok) {
          const { status, statusText } = res;

          throw new Error(`Error ${status}: ${statusText}`);
        }

        response = await res.json();
        setData(response);
        setLoading(false);

        return response;
      } catch (error) {
        setError(error);
        setLoading(false);
        throw error;
      }
    }
  };

  return {
    loading,
    error,
    data,
    fetch: useCallback(execute, [result, msalError]), // to avoid infinite calls when inside a `useEffect`
  };
};
