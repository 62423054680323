import React, { useCallback } from "react";
import { Button, Col, Row, Table, Tag } from "antd";
import { FormattedMessage } from "react-intl";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

import { DraggableBodyRow } from "./DraggableBodyRow";

function Schedules({
  schedules = [],
  setSchedules,
  onEditSchedule,
  status,
  deleteSchedule,
  edited,
  cityportal
}) {
  const addKeyToSchedules = schedules
    .map((sc, idx) => ({ ...sc, key: idx }))
    .sort((a, b) => a.priority - b.priority);

  const columns = [
    {
      title: <FormattedMessage id="Priority" />,
      dataIndex: "priority",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.priority - b.priority,
      key: "priority",
    },
    {
      title: <FormattedMessage id="DateRange" />,
      render: function (_, schedule) {
        const { timeSchedule } = schedule;
        const { dateRangeType, startDate, endDate } = timeSchedule;
        if (dateRangeType === "LENGTH_OF_CAMPAIGN" || !startDate || !endDate) {
          return <FormattedMessage id={dateRangeType} />;
        }
        return `${moment(startDate).format("DD-MM-YYYY")} - ${moment(
          endDate
        ).format("DD-MM-YYYY")}`;
      },
      key: "dateRange",
    },
    {
      title: <FormattedMessage id="DaysOfTheWeek" />,
      dataIndex: "days",
      render: function (_, schedule) {
        const { timeSchedule } = schedule;
        const { daysOfWeekType, daysOfWeekList } = timeSchedule;
        if (daysOfWeekType === "ALL_DAYS") {
          return <FormattedMessage id={daysOfWeekType} />;
        }
        return daysOfWeekList.join(", ");
      },
      key: "days",
    },
    {
      title: <FormattedMessage id="Schedule" />,
      render: function (_, schedule) {
        const { timeSchedule } = schedule;
        const { timeRangeType, startTime, endTime } = timeSchedule;
        if (timeRangeType === "ALL_TIME" || !startTime || !endTime) {
          return <FormattedMessage id={timeRangeType} />;
        }
        return `${moment(startTime).format("HH:mm")} - ${moment(endTime).format(
          "HH:mm"
        )}`;
      },
      key: "schedule",
    },
    {
      title: <FormattedMessage id="Tags" />,
      dataIndex: "tags",
      render: function (_, schedule) {
        const { locationSchedule } = schedule;
        const {
          locationScheduleType,
          selectedLocations = [],
        } = locationSchedule;
        if (locationScheduleType === "ALL_LOCATIONS") {
          return <FormattedMessage id={locationScheduleType} />;
        }
        return selectedLocations?.map((tag, index) => (
          <Tag color="blue" key={index}>
            {tag}
          </Tag>
        ));
      },
      key: "tags",
    },
    {
      title: <FormattedMessage id="Creative" />,
      dataIndex: "creatives",
      render: function (creatives = []) {
        return creatives.length > 0 ? `x${creatives.length}` : "--";
      },
      key: "creative",
    },
    {
      title: <FormattedMessage id="Actions" />,
      dataIndex: "actions",
      render: function (_, schedule) {
        return (
          <>
            <Button
              disabled={status !== "draft" || cityportal}
              onClick={() => onEditSchedule(schedule)}
            >
              <EditOutlined />
            </Button>
            {"  "}
            <Button
              disabled={status !== "draft" || cityportal}
              onClick={() => {
                deleteSchedule(schedule);
              }}
            >
              <DeleteOutlined />
            </Button>
          </>
        );
      },
      key: "actions",
    },
  ];
  const DraggableComponent = (props) => (
    <DraggableBodyRow edited={edited} {...props} cityportal={cityportal} />
  );

  const components = {
    body: {
      row: DraggableComponent,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = addKeyToSchedules[dragIndex];
      const hoverRow = addKeyToSchedules[hoverIndex];

      const newOrderSchedules = addKeyToSchedules.map((sch) => {
        if (sch.priority === dragRow.priority) {
          return { ...sch, priority: hoverRow.priority };
        }

        if (sch.priority === hoverRow.priority) {
          return { ...sch, priority: dragRow.priority };
        }

        return sch;
      });

      setSchedules(newOrderSchedules);
    },
    [addKeyToSchedules, setSchedules]
  );

  return (
    <Row style={{ marginTop: "16px" }}>
      <DndProvider backend={HTML5Backend}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={addKeyToSchedules}
            components={components}
            onRow={(_, index) => ({
              index,
              moveRow,
            })}
          />
        </Col>
      </DndProvider>
    </Row>
  );
}

export default Schedules;
