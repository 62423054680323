import React, { useEffect, useRef } from "react";

export default function VideoElement({ url, height }) {
    const videoRef = useRef();
    const previousUrl = useRef(url);
  
    useEffect(() => {
      if (previousUrl.current === url) {
        return;
      }
  
      if (videoRef.current) {
        videoRef.current.load();
      }
  
      previousUrl.current = url;
    }, [url]);
  
    return (
      <video height={height} controls ref={videoRef}>
        <source
          src={url}
        />
      </video>
    )
  }