import React, { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { Menu, Dropdown, Avatar, Layout, Row, Col, Select } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { FormattedMessage, injectIntl } from "react-intl";

import { LanguageContext } from "../hooks/";

const { Option } = Select;
function Header() {
  const { intl, setIntl } = useContext(LanguageContext);
  const { instance } = useMsal();

  const logout = async () => {
    instance.logoutRedirect();
  };

  const menu = (
    <Menu placement="bottomLeft">
      <Menu.Item onClick={logout}>
        <FormattedMessage id="Logout" />
      </Menu.Item>
    </Menu>
  );

  const changeLanguage = (value) => {
    setIntl({ ...intl, locale: value });
  };

  return (
    <Layout.Header padding={0} style={{ background: "#ffffff" }}>
      <Col span={24}>
        <Row type="flex" justify="end">
          <Col style={{ paddingRight: "20px", paddingTop: "3px" }}>
            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link"
                href="/"
                onClick={(e) => e.preventDefault()}
              >
                <Avatar shape="square" icon={<UserOutlined />} />
              </a>
            </Dropdown>
          </Col>
          <Col style={{ paddingRight: "20px", paddingTop: "3px" }}>
            <Select
              defaultValue="fr"
              onChange={changeLanguage}
              value={intl.locale}
            >
              <Option value="en">EN</Option>
              <Option value="fr">FR</Option>
            </Select>
          </Col>
        </Row>
      </Col>
    </Layout.Header>
  );
}

export default injectIntl(Header);
