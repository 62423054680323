import React from "react";
import ReactDOM from "react-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const { REACT_APP_AZURE_CLIENT, REACT_APP_AZURE_TENANT } = process.env;

const msalConfig = {
  auth: {
    clientId: REACT_APP_AZURE_CLIENT, // This is the ONLY mandatory field that you need to supply.
    authority: `https://login.microsoftonline.com/${REACT_APP_AZURE_TENANT}`, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    clientCapabilities: ["CP1"], // this lets the resource owner know that this client is capable of handling claims challenge.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// you can select which account application should sign out
// const logoutRequest = {
//   account: msalConfig.getAccountByHomeId(homeAccountId),
// };

// msalConfig.logoutRedirect(logoutRequest);

ReactDOM.render(
  <React.StrictMode>
    <App instance={msalInstance} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
