const { REACT_APP_OKTA_CLIENT_ID, REACT_APP_OKTA_ISSUER, REACT_APP_API_URL,REACT_APP_POST_LOGOUT_URI } = process.env;

export const config = {
  okta: {
    issuer: `https://${REACT_APP_OKTA_ISSUER || 'dev-93218725.okta.com'}/oauth2/default`,
    clientId: REACT_APP_OKTA_CLIENT_ID || '0oabqkhbg2H5MCBgv5d6',
    redirectUri: `${window.location.protocol}//${window.location.host}/login/callback`,
    postLogoutRedirectUri: REACT_APP_POST_LOGOUT_URI || 'http://localhost:3000',
    scopes: ["openid", "profile", "email"],
    // pkce: true,
  },
  api: REACT_APP_API_URL || "https://api.cms.clearchannel.io"
};