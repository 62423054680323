import {
  Card,
  Col,
  DatePicker,
  Row,
  Table,
  Typography,
  Tag,
  Switch,
} from "antd";
import { FormattedMessage } from "react-intl";
import moment from "moment";

const { Title } = Typography;

function CampaignDetail({ startDate, endDate, onChange, status, version }) {
  function disabledDate(current) {
    return current && current < moment().endOf("day");
  }

  const handleDateChange = (date, dateString, type) => {
    onChange(date, type);
  };

  const columns = [
    {
      title: <FormattedMessage id="Name" />,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <FormattedMessage id="Value" />,
      dataIndex: "value",
      key: "value",
      render: (text, record) => {
        const isStartDate = record.key === 1;
        return (
          <DatePicker
            bordered={false}
            defaultValue={moment(text, "YYYY-MM-DD")}
            disabledDate={disabledDate}
            disabled={status !== "draft"}
            onChange={(date, dateString) =>
              handleDateChange(
                date,
                dateString,
                isStartDate ? "startDate" : "endDate"
              )
            }
          />
        );
      },
    },
  ];

  const dataSource = [
    {
      key: 1,
      name: <FormattedMessage id="StartDate" />,
      value: startDate,
    },
    {
      key: 2,
      name: <FormattedMessage id="EndDate" />,
      value: endDate,
    },
  ];

  function formatTitle({ version, status }) {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>
          <FormattedMessage id="CampagneDetails" />
        </span>
        <div>
          <Tag>
            {status === "draft" ? (
              <FormattedMessage id="Draft" />
            ) : (
              <FormattedMessage id="Submitted" />
            )}
          </Tag>
          <Tag>V{version}</Tag>
        </div>
      </div>
    );
  }

  return (
    <Col span={10}>
      <Card size="small" title={formatTitle({ version, status })}>
        <Table
          pagination={false}
          bordered
          gutter={0}
          showHeader={false}
          columns={columns}
          dataSource={dataSource}
        />
      </Card>
    </Col>
  );
}

function TagDetail({ cityportal = false, tags = [], onChange, status }) {
  return (
    <Col span={14}>
      <Card size="small" title="Tags">
        <>
          {tags.map((tag, index) => (
            <Tag color="blue" key={index}>
              {tag.name}
            </Tag>
          ))}
        </>
      </Card>
      <Card size="small" title="City Portal" style={{ marginTop: "10px" }}>
        <Row>
          <Switch
            onChange={onChange}
            checked={cityportal}
            disabled={status !== "draft"}
          />
        </Row>
      </Card>
    </Col>
  );
}

function ContentPlanDetail({
  advertiser,
  cityportal = false,
  startDate,
  endDate,
  tags,
  onCityPortalChange,
  onDateChange,
  status,
  version,
}) {
  return (
    <>
      <Title>{advertiser}</Title>
      <Row gutter={16}>
        <CampaignDetail
          startDate={startDate}
          endDate={endDate}
          onChange={onDateChange}
          version={version}
          status={status}
        />
        <TagDetail
          tags={tags}
          status={status}
          onChange={onCityPortalChange}
          cityportal={cityportal}
        />
      </Row>
    </>
  );
}

export default ContentPlanDetail;
