import React from "react";
import moment from "moment";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Radio,
  Row,
  Select,
  TimePicker,
  DatePicker,
} from "antd";
import { FormattedMessage, injectIntl } from "react-intl";

import CreativeCard from "./CreativeCard";

function disableSubmit(creatives, timeSchedule) {
  if (!creatives.length ) return true
  if (timeSchedule.dateRangeType === "SPECIFIC_RANGE" && (!timeSchedule?.startDate || !timeSchedule?.endDate)) return true
  return false
}

function Footer({ onSave, creatives, timeSchedule }) {
  return (
    <Row justify="end">
      <Button onClick={onSave} disabled={disableSubmit(creatives, timeSchedule)} type="primary">
        <FormattedMessage id="Add" />
      </Button>
    </Row>
  );
}

function ScheduleDrawer({
  startDate,
  endDate,
  open,
  onClose,
  getScheduleAdd,
  getScheduleEdit,
  tags = [],
  editingSchedule,
  setEditingSchedule,
  intl,
}) {
  const {
    creatives = [],
    timeSchedule = {},
    locationSchedule = {},
  } = editingSchedule;
  
  function onSave() {
    editingSchedule.priority
      ? getScheduleEdit({
          priority: editingSchedule.priority,
          timeSchedule,
          creatives,
          locationSchedule,
          createdDateTime: new Date(),
        })
      : getScheduleAdd({
          timeSchedule,
          creatives,
          locationSchedule,
          createdDateTime: new Date(),
        });

    return onClose();
  }

  function getDateRangeInitialValue({ startDate, endDate }) {
    if (startDate && endDate) {
      return [moment(startDate), moment(endDate)];
    }

    return null;
  }

  function getTimeRangeInitialValue({ startTime, endTime }) {
    if (startTime && endTime) {
      return [moment(startTime), moment(endTime)];
    }

    return [moment(), moment().add(1, "hour")];
  }

  function getSelectedLocationsInitialValue({ selectedLocations }, event) {
    if (selectedLocations.length > 0) {
      return selectedLocations;
    }
    
    if (locationSchedule.locationScheduleType === "ALL_LOCATIONS") {
      if (event && event === "ALL_LOCATIONS"){
        return [];
      } else if (event) {
        return [tags[0].name];
      }
      return []
    }
    return [tags[0].name];
  }

  function disabledDate(current) {
    if (current <= moment(startDate)) return true;

    if (current >= moment(endDate).add(1, "d")) return true;

    return false;
  }

  return (
    <Drawer
      title={<FormattedMessage id="AddContentPlan" />}
      push
      closable
      placement="right"
      mask={false}
      width="460px"
      visible={open}
      onClose={onClose}
      footer={<Footer onSave={onSave} creatives={creatives} timeSchedule={timeSchedule}/>}
    >
      <Card
        title={<FormattedMessage id="DateRange" />}
        size="small"
        style={{ marginTop: "10px" }}
      >
        <Radio.Group
          value={timeSchedule.dateRangeType}
          onChange={(event) => {
            setEditingSchedule({
              ...editingSchedule,
              timeSchedule: {
                ...editingSchedule.timeSchedule,
                dateRangeType: event.target.value,
              },
            });
          }}
        >
          <Radio value="LENGTH_OF_CAMPAIGN">
            <FormattedMessage id="CampaignDuration" />
          </Radio>
          <Radio value="SPECIFIC_RANGE">
            <FormattedMessage id="SpecificRange" />
          </Radio>
        </Radio.Group>
        <DatePicker.RangePicker
          disabledDate={disabledDate}
          value={getDateRangeInitialValue(timeSchedule)}
          bordered={false}
          style={{ marginTop: "8px" }}
          disabled={timeSchedule.dateRangeType === "LENGTH_OF_CAMPAIGN"}
          onChange={function (dates) {
            const [startDate, endDate] = dates;
            setEditingSchedule({
              ...editingSchedule,
              timeSchedule: {
                ...editingSchedule.timeSchedule,
                startDate,
                endDate,
              },
            });
          }}
        />
      </Card>
      <Card
        title={<FormattedMessage id="DaysOfTheWeek" />}
        size="small"
        style={{ marginTop: "10px" }}
      >
        <Radio.Group
          value={timeSchedule.daysOfWeekType}
          onChange={(event) => {
            setEditingSchedule({
              ...editingSchedule,
              timeSchedule: {
                ...editingSchedule.timeSchedule,
                daysOfWeekType: event.target.value,
                daysOfWeekList: timeSchedule.daysOfWeekList ? timeSchedule.daysOfWeekList : ["Mon"]
              },
            });
          }}
        >
          <Radio value="ALL_DAYS">
            <FormattedMessage id="Everyday" />
          </Radio>
          <Radio value="SPECIFIC_DAYS">
            <FormattedMessage id="SpecificDays" />
          </Radio>
        </Radio.Group>
        <Checkbox.Group
          style={{ marginTop: "8px" }}
          value={
            timeSchedule.daysOfWeekList ? timeSchedule.daysOfWeekList : ["Mon"]
          }
          disabled={timeSchedule.daysOfWeekType === "ALL_DAYS"}
          onChange={(checks) => {
            setEditingSchedule({
              ...editingSchedule,
              timeSchedule: {
                ...editingSchedule.timeSchedule,
                daysOfWeekList: checks.length === 0 ? ["Mon"] : checks,
              },
            });
          }}
        >
          <Row>
            <Col span={8}>
              <Checkbox value="Mon">
                <FormattedMessage id="Mon" />
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Tue">
                <FormattedMessage id="Tue" />
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Wed">
                <FormattedMessage id="Wed" />
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Thu">
                <FormattedMessage id="Thu" />
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Fri">
                <FormattedMessage id="Fri" />
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Sat">
                <FormattedMessage id="Sat" />
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Sun">
                <FormattedMessage id="Sun" />
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Card>
      <Card
        title={<FormattedMessage id="Hours" />}
        size="small"
        style={{ marginTop: "10px" }}
      >
        <Radio.Group
          value={timeSchedule.timeRangeType}
          onChange={(event) => {
            setEditingSchedule({
              ...editingSchedule,
              timeSchedule: {
                ...editingSchedule.timeSchedule,
                timeRangeType: event.target.value,
              },
            });
          }}
        >
          <Radio value="ALL_TIME">
            <FormattedMessage id="AllTime" />
          </Radio>
          <Radio value="SPECIFIC_TIMES">
            <FormattedMessage id="SpecificRange" />
          </Radio>
        </Radio.Group>

        <TimePicker.RangePicker
          value={getTimeRangeInitialValue(timeSchedule)}
          bordered={false}
          style={{ marginTop: "8px" }}
          disabled={timeSchedule.timeRangeType === "ALL_TIME"}
          onChange={function (times) {
            const [startTime, endTime] = times;
            setEditingSchedule({
              ...editingSchedule,
              timeSchedule: {
                ...editingSchedule.timeSchedule,
                startTime,
                endTime,
              },
            });
          }}
        />
      </Card>
      <Card
        title={<FormattedMessage id="Tags" />}
        size="small"
        style={{ marginTop: "10px" }}
      >
        <Radio.Group
          value={locationSchedule.locationScheduleType}
          onChange={(event) => {
            setEditingSchedule({
              ...editingSchedule,
              locationSchedule: {
                ...editingSchedule.locationSchedule,
                locationScheduleType: event.target.value,
                selectedLocations: getSelectedLocationsInitialValue(locationSchedule, event.target.value)
              },
            });
          }}
        >
          <Radio value="ALL_LOCATIONS">
            <FormattedMessage id="AllLocation" />
          </Radio>
          <Radio value="SPECIFIC_LOCATIONS">
            <FormattedMessage id="SpecificLocations" />
          </Radio>
        </Radio.Group>
        <Select
          style={{ width: "100%", marginTop: "10px" }}
          mode="multiple"
          value={getSelectedLocationsInitialValue(locationSchedule)}
          placeholder={intl.formatMessage({ id: "SelectTags" })}
          disabled={locationSchedule.locationScheduleType === "ALL_LOCATIONS"}
          onChange={(values) => {
            setEditingSchedule({
              ...editingSchedule,
              locationSchedule: {
                ...editingSchedule.locationSchedule,
                selectedLocations: !values.length ? [tags[0]?.name] : values
              },
            });
          }}
        >
          {tags.map((tag) => (
            <Select.Option key={tag.name}>{tag.name}</Select.Option>
          ))}
        </Select>
      </Card>
      <CreativeCard
        creatives={creatives}
        setCreatives={(creatives) =>
          setEditingSchedule((values) => ({ ...values, creatives }))
        }
      />
    </Drawer>
  );
}

export default injectIntl(ScheduleDrawer);
