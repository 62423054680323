import React, { useState } from "react";
import { Menu } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import UploadCreativeUrlCard from "./UploadCreativeUrlCard";
import UploadCreativeFileCard from "./UploadCreativeFileCard";

function UploadCreative({ onSave }) {

  const [files, setFiles] = useState([]);
  const [selectMode, setSelectMode] = useState("file")

  const changeMode = (e) => {
    setSelectMode(e.key)
    setFiles([])
  }

  return (
    
    <>
      <div>
        <Menu onClick={e => changeMode(e)} selectedKeys={[selectMode]} mode="horizontal" style={{marginBottom: "20px"}}>
          <Menu.Item key="file">
            <FormattedMessage id="UploadFile" />
          </Menu.Item>
          <Menu.Item key="url">
            <FormattedMessage id="UploadUrl" />
          </Menu.Item>
        </Menu>
        {
          selectMode === "file" ?
        <UploadCreativeFileCard onSave={onSave} setFiles={setFiles} files={files}/> :
        <UploadCreativeUrlCard onSave={onSave}/>
        }
      </div>
    </>
  );
}

export default injectIntl(UploadCreative);
