const en = {
  Campaigns: "Campaigns",
  Creatives: "Creatives",
  ImportPlay: "Import PLAY",
  Date: "Date",
  Name: "Name",
  FileType: "File Type",
  Status: "Status",
  PlayFileUploadSuccess: "Play files uploaded successfully",
  CreativesUploadSuccess: "Creative or creatives uploaded successfully",
  ErrorUpload: "An error occurred while uploading the Play files",
  User: "User",
  DragSchedule: "Drag and drop your files to this area to upload",
  ClickBrowserFile: "You can click here to browser for files",
  Search: "Search",
  ValidUrl: "Please provide a valid URL",
  ValidTitle: "Please type a title",
  UploadFiles: "Upload Files",
  UploadFile: "Upload File",
  UploadUrl: "Upload URL",
  Title: "Title",
  Annonceur: "Annonceur",
  Client: "Client",
  WriteUrl: "Write here the URL you want to upload",
  ErroFetchPlan: "An error occurred while fetching the content plan",
  Draft: "Draft",
  Submitted: "Submitted",
  FilterContractStatus: "Filter Contract Status",
  CustomerAdv: "Customer - Advertiser",
  StartDate: "Start Date",
  EndDate: "End Date",
  Version: "Version",
  Tags: "Tags",
  DeleteCreative: "Delete Creative",
  DeleteMsg: "You're trying to delete creative {title}",
  ErrorDelete: "An error occured while deleting the creative",
  Save: "Save",
  ErrorFetchCreatives: "An error occurred while fetching the creatives",
  Add: "Add",
  Added: "Added",
  AddCreative: "Add Creative",
  Done: "Done",
  Edit: "Edit",
  Value: "Value",
  CampagneDetails: "Campagne Details",
  Creations: "Creations",
  Priority: "Priority",
  DateRange: "Date Range",
  DaysOfTheWeek: "Days of the week",
  Schedule: "Schedule",
  Creative: "Creative",
  Actions: "Actions",
  ContractNumber: "Contract Number",
  AddContentPlan: "Add Content Plan",
  CampaignDuration: "Campaign Duration",
  SpecificRange: "Specific Range",
  Everyday: "Everyday",
  SpecificDays: "Specific Days",
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  Sun: "Sun",
  AllTime: "All Time",
  AllLocation: "All Location",
  SpecificLocations: "Specific Locations",
  SelectTags: "Select Tags",
  Hours: "Hours",
  ErrorFetchPlans: "An error occurred while fetch the content plans",
  LENGTH_OF_CAMPAIGN: "Length of Campaign",
  ALL_DAYS: "All days",
  ALL_TIME: "All time",
  ALL_LOCATIONS: "All locations",
  Logout: "Logout",
  Delete: "Delete",
  TitleAscend: "Title - ascend",
  TitleDescend: "Title - descend",
  ClientAscend: "Client - ascend",
  ClientDescend: "Client - descend",
  AnnonceurAscend: "Advertiser - ascend",
  AnnonceurDescend: "Advertiser - descend",
  DateAscend: "Date - ascend",
  DateDescend: "Date - descend",
  NameAscend: "Name - ascend",
  NameDescend: "Name - descend",
  UserAscend: "User - ascend",
  UserDescend: "User - descend",
  FiletypeAscend: "Filetype - ascend",
  FiletypeDescend: "Filetype - descend",
  StartDateAscend: "Start Date - ascend",
  StartDateDescend: "Start Date - descend",
  EndDateAscend: "End Date - ascend",
  EndDateDescend: "End Date - descend",
  UploadedAscend: "Creation date - ascend",
  UploadedDescend: "Creation date - descend",
};

export default en;
