import React, { useState, useEffect } from "react";
import { Button, Drawer, Input, List, message, Pagination, Row } from "antd";
import {
  MinusOutlined,
  PlusOutlined,
  FileZipOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { injectIntl, FormattedMessage } from "react-intl";

import VideoElement from "./VideoElement";
import { useFetchWithMsal } from "../hooks";

const {
  REACT_APP_BUCKET_URL = "https://ccfr-cms-new-prod-frontend-ng.s3.eu-west-3.amazonaws.com",
} = process.env;

function AddCreativeDrawer({
  selectedCreatives = [],
  setSelectedCreatives,
  open,
  onClose,
  onSave,
  intl,
}) {
  const { fetch, data } = useFetchWithMsal();
  const [creatives, setCreatives] = useState([]);
  const [total, setTotal] = useState(6);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const onSearch = (search) => {
    if (search) {
      return setSearch(search);
    }

    setSearch("");
  };

  useEffect(() => {
    let isMounted = true;

    async function getCreatives() {
      let url = `creatives?page=${page}&pagesize=6`;

      if (search) {
        url = `${url}&q=${search}`;
      }

      try {
        await fetch(url);
      } catch (error) {
        console.log(error);
        message.error(
          "Une erreur s'est produite lors de la récupération des créations"
        );
      }
    }

    if (isMounted) {
      getCreatives();
    }

    return () => {
      isMounted = false;
    };
  }, [page, search, total, fetch]);

  useEffect(() => {
    if (!data) return;

    const { docs: creatives = [], total } = data;

    setCreatives(
      creatives.map((creative, index) => ({ ...creative, key: index }))
    );
    setTotal(total);
  }, [data]);

  const onCreativeUncheck = (id) => {
    return setSelectedCreatives(
      selectedCreatives.filter((creative) => creative.id !== id)
    );
  };

  function onCreativeCheck(id) {
    return function () {
      const creative = creatives.find((creative) => creative.id === id);
      return setSelectedCreatives([...selectedCreatives, creative]);
    };
  }

  function isCreativeChecked(id) {
    return !!selectedCreatives.find((creative) => creative.id === id);
  }

  function getCreativeActions(id) {
    return !isCreativeChecked(id)
      ? [
          <Button type="dashed" key="add" onClick={onCreativeCheck(id)}>
            <PlusOutlined />
            <FormattedMessage id="Add" />
          </Button>,
        ]
      : [
          <Button type="dashed" key="add" onClick={() => onCreativeUncheck(id)}>
            <MinusOutlined />
            <FormattedMessage id="Added" />
          </Button>,
        ];
  }

  return (
    <Drawer
      title={<FormattedMessage id="AddCreative" />}
      placement="right"
      closable
      width="460px"
      mask={false}
      visible={open}
      onClose={onClose}
      footer={
        <Row justify="end">
          <Button type="primary" onClick={() => onSave(selectedCreatives)}>
            <FormattedMessage id="Done" />
          </Button>
        </Row>
      }
    >
      <Input.Search
        placeholder={intl.formatMessage({ id: "Search" })}
        allowClear
        onSearch={onSearch}
      />
      <List
        itemLayout="vertical"
        size="large"
        dataSource={creatives}
        renderItem={(creative) => {
          const { bucketKey = "", url = "" } = creative;
          const isVideo = bucketKey.match(/^.*.(mp4|webm|mov|wmv)$/i);
          const isImage = bucketKey.match(/^.*.(png|gif|jpeg|jpg)$/i);
          const isHTML = bucketKey.match(/^.*.(html|zip)$/i);
          const isLink = !bucketKey && url;

          function getElement(url) {
            if (isVideo) {
              return <VideoElement height="120" url={url} />;
            } else if (isImage) {
              return (
                <img
                  style={{ height: "100px", objectFit: "cover" }}
                  alt="creative preview"
                  src={url}
                />
              );
            } else if (isHTML) {
              return (
                <FileZipOutlined
                  style={{ fontSize: "48px", margin: "26px 0" }}
                />
              );
            } else if (isLink) {
              return (
                <LinkOutlined style={{ fontSize: "48px", margin: "26px 0" }} />
              );
            }

            return <></>;
          }
          return (
            <List.Item
              actions={getCreativeActions(creative.id)}
              extra={getElement(
                `${REACT_APP_BUCKET_URL}/${creative["bucketKey"]}`
              )}
            >
              <List.Item.Meta
                title={creative.title || "N/A"}
                description={`Client: ${
                  creative.client || "N/A"
                } - Announcer: ${creative.annonceur || "N/A"}`}
              />
            </List.Item>
          );
        }}
      />
      <Pagination
        current={page}
        defaultPageSize={6}
        onChange={(page) => setPage(page)}
        total={total}
        style={{ textAlign: "center" }}
      />
    </Drawer>
  );
}

export default injectIntl(AddCreativeDrawer);
