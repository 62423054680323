import React, { useEffect, useState } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Row,
  Table,
  Tag,
  message,
} from "antd";
import { injectIntl, FormattedMessage } from "react-intl";

import { Link } from "react-router-dom";
import moment from "moment";
import { useFetchWithMsal } from "../hooks";

const sortOptions = [
  {
    id: "AnnonceurDescend",
    value: "announcer-desc",
    sort: "advertiser",
    dir: "desc",
  },
  {
    id: "AnnonceurAscend",
    value: "announcer-asc",
    sort: "advertiser",
    dir: "asc",
  },
  {
    id: "UploadedDescend",
    value: "uploaded-desc",
    sort: "createdAt",
    dir: "desc",
  },
  {
    id: "UploadedAscend",
    value: "uploaded-asc",
    sort: "createdAt",
    dir: "asc",
  },
  {
    id: "StartDateDescend",
    value: "startDate-desc",
    sort: "createdAt",
    dir: "desc",
  },
  {
    id: "StartDateAscend",
    value: "startDate-asc",
    sort: "createdAt",
    dir: "asc",
  },
  {
    id: "EndDateDescend",
    value: "endDate-desc",
    sort: "endDate",
    dir: "desc",
  },
  {
    id: "EndDateAscend",
    value: "endDate-asc",
    sort: "endDate",
    dir: "asc",
  },
];

const columns = [
  {
    title: <FormattedMessage id="ContractNumber" />,
    dataIndex: "orderId",
    render: (orderId, contentPlan) => (
      <Link to={`/content-plans/${contentPlan._id}`}>{orderId}</Link>
    ),
    key: "orderId",
  },
  {
    title: <FormattedMessage id="CustomerAdv" />,
    dataIndex: "advertiser",
    key: "advertiser",
  },
  {
    title: <FormattedMessage id="StartDate" />,
    dataIndex: "startDate",
    key: "startDate",
    render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "--"),
  },
  {
    title: <FormattedMessage id="EndDate" />,
    dataIndex: "endDate",
    key: "endDate",
    render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "--"),
  },
  {
    title: <FormattedMessage id="Version" />,
    dataIndex: "version",
    render: (version) => `V${version}`,
    key: "version",
  },
  {
    title: <FormattedMessage id="Status" />,
    dataIndex: "status",
    render: (status) =>
      status === "draft" ? (
        <FormattedMessage id="Draft" />
      ) : (
        <FormattedMessage id="Submitted" />
      ),
    key: "status",
  },
  {
    title: <FormattedMessage id="Tags" />,
    render: (tags = []) => (
      <>
        {tags.map((tag, index) => {
          return (
            <Tag color="blue" key={tag.name + " " + index}>
              {tag.name}
            </Tag>
          );
        })}
      </>
    ),
    dataIndex: "tags",
    key: "tags",
  },
];

const ContentPlans = ({ intl }) => {
  const { fetch, data, loading } = useFetchWithMsal();
  const [contentPlans = [], setContentPlans] = useState([]);
  const [search, setSearch] = useState({});
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(6);
  const [pageSize, changePageSize] = useState(10);
  const [sort, setSort] = useState({ sort: "createdAt", dir: "desc" });

  useEffect(() => {
    let isMounted = true;

    async function getContentPlans() {
      try {
        let url = `content-plans?page=${page}&pagesize=${pageSize}&sort=${sort.sort}&dir=${sort.dir}&archived=false`;

        if (search && Object.keys(search).length !== 0) {
          const searchQueryString = new URLSearchParams(search).toString();
          url = `${url}&${searchQueryString}`;
        }

        await fetch(url);
      } catch (error) {
        console.log(error);
        message.error(
          "Une erreur s'est produite lors de la récupération du plan de contenu"
        );
      }
    }

    if (isMounted) {
      getContentPlans();
    }

    return () => {
      isMounted = false;
    };
  }, [page, search, pageSize, sort, fetch]);

  useEffect(() => {
    if (!data) return;

    const { docs: contentPlans = [], total } = data;

    setContentPlans(
      contentPlans.map((contentPlan, index) => ({ ...contentPlan, key: index }))
    );
    setTotal(total);
  }, [data]);

  const onSearch = (values = {}) => {
    const { text, dates, status } = values;
    const searchObject = {};

    if (text) {
      searchObject.text = text;
    }

    if (dates && dates.length) {
      const [startDate, endDate] = dates;
      searchObject.startDate = startDate;
      searchObject.endDate = endDate;
    }

    if (status && status.length) {
      searchObject.status = status;
    }

    return setSearch(searchObject);
  };

  const changeFilter = (value) => {
    const sortOption = sortOptions.find(
      (sortOption) => sortOption.value === value
    );

    if (!sortOption) {
      return;
    }

    setPage(1);
    setSort({ sort: sortOption.sort, dir: sortOption.dir });
  };

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <Row style={{ paddingBottom: "10px" }}>
        <Form layout="inline" onFinish={onSearch}>
          <Row type="flex" justify="space-between">
            <Form.Item name="text">
              <Input
                placeholder={intl.formatMessage({ id: "Search" })}
                allowClear
              />
            </Form.Item>
            <Form.Item name="dates">
              <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item name="status">
              <Select
                placeholder={intl.formatMessage({
                  id: "FilterContractStatus",
                })}
                mode="tags"
                style={{ minWidth: "230px" }}
              >
                {/* <Select.Option key="new">New</Select.Option> */}
                <Select.Option key="draft">
                  <FormattedMessage id="Draft" />
                </Select.Option>
                <Select.Option key="submitted">
                  <FormattedMessage id="Submitted" />
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="dashed" htmlType="submit">
                <FormattedMessage id="Search" />
              </Button>
            </Form.Item>
          </Row>
        </Form>
        <Select
          placeholder="Select filter"
          style={{ flex: 1, maxWidth: "230px", marginLeft: "6px" }}
          onChange={changeFilter}
          defaultValue="startDate-desc"
        >
          {sortOptions.map((option) => (
            <Select.Option key={option.id} value={option.value}>
              <FormattedMessage id={option.id} />
            </Select.Option>
          ))}
        </Select>
      </Row>
      <Row style={{ paddingBottom: "10px" }}>
        {loading ? (
          <>Loading...</>
        ) : (
          <Table
            columns={columns}
            dataSource={contentPlans}
            pagination={{
              current: page,
              pageSize: pageSize,
              onChange: (page, pagesize) => {
                changePageSize(pagesize);
                setPage(page);
              },
              total: total,
            }}
          />
        )}
      </Row>
    </MsalAuthenticationTemplate>
  );
};

export default injectIntl(ContentPlans);
