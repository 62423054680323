const fr = {
  Campaigns: "Campagnes",
  Creatives: "Créations",
  ImportPlay: "Importer PLAY",
  Date: "Date",
  Name: "Nom",
  FileType: "Type de fichier",
  Status: "Statut",
  NEW: 'Nouveau',
  SUCCEED: 'Réussir',
  FAILED: 'Manqué',
  PlayFileUploadSuccess: "Lire les fichiers téléchargés avec succès",
  CreativesUploadSuccess: "Création ou créations importées avec succès",
  ErrorUpload:
    "Une erreur s'est produite lors du téléchargement des fichiers Play",
  User: "Utilisateur",
  DragSchedule:
    "Faites glisser les fichiers dans cette zone pour les télécharger (ou cliquez ici)",
  ClickBrowserFile: "Vous pouvez cliquer ici pour rechercher des fichiers",
  Search: "Rechercher",
  ValidUrl: "Veuillez fournir une URL valide",
  ValidTitle: "Veuillez saisir un titre",
  UploadFiles: "Télécharger des fichiers",
  UploadFile: "Télécharger un fichier",
  UploadUrl: "Télécharger URL",
  Title: "Titre",
  Annonceur: "Annonceur",
  Client: "Client",
  WriteUrl: "Écrivez ici l'URL que vous souhaitez télécharger",
  ErroFetchPlan:
    "Une erreur s'est produite lors de la récupération du plan de contenu",
  Draft: "Brouillon",
  Submitted: "Soumis",
  FilterContractStatus: "Filtrer le statut du contrat",
  CustomerAdv: "Client - Annonceur",
  StartDate: "Date de début",
  EndDate: "Date de fin",
  Version: "Version",
  Tags: "Balises",
  DeleteCreative: "Supprimer la création",
  DeleteMsg: "Vous essayez de supprimer la création {title}",
  ErrorDelete:
    "Une erreur s'est produite lors de la suppression de la création",
  Save: "Sauvegarder",
  ErrorFetchCreatives:
    "Une erreur s'est produite lors de la récupération des créations",
  Add: "Ajouter",
  Added: "Ajouté",
  AddCreative: "Ajouter une création",
  Done: "Fait",
  Edit: "Éditer",
  Value: "Valeur",
  CampagneDetails: "Détails de la campagne",
  Creations: "Créations",
  Priority: "Priorité",
  DateRange: "Plage de dates",
  DaysOfTheWeek: "Jours de la semaine",
  Schedule: "Horaire",
  Creative: "Création",
  Actions: "Actions",
  ContractNumber: "Numéro de contrat",
  AddContentPlan: "Ajouter une ligne de planification",
  CampaignDuration: "Durée de la campagne",
  SpecificRange: "Plage spécifique",
  Everyday: "Tous les jours",
  SpecificDays: "Jours spécifiques",
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mer",
  Thu: "Jeu",
  Fri: "Ven",
  Sat: "Sam",
  Sun: "Dim",
  AllTime: "Tout le temps",
  AllLocation: "Tous les tags",
  SpecificLocations: " Tags spécifiques",
  SelectTags: "Sélectionnez les balises",
  Hours: "Horaires",
  ErrorFetchPlans:
    "Une erreur s'est produite lors de la récupération des plans de contenu",
  LENGTH_OF_CAMPAIGN: "Toute la durée",
  ALL_DAYS: "Tous les jours",
  ALL_TIME: "Toutes les heures",
  ALL_LOCATIONS: "Tous les emplacements",
  Logout: "Déconnexion",
  Delete: "Supprimer",
  TitleAscend: "Titre - Croissant",
  TitleDescend: "Titre - Décroissant",
  ClientAscend: "Cliente - Croissant",
  ClientDescend: "Cliente - Décroissant",
  AnnonceurAscend: "Annonceur - Croissant",
  AnnonceurDescend: "Annonceur - Décroissant",
  DateAscend: "Date - Croissant",
  DateDescend: "Date - Décroissant",
  NameAscend: "Nom - Croissant",
  NameDescend: "Nom - Décroissant",
  UserAscend: "Utilisatrice - Croissant",
  UserDescend: "Utilisatrice - Décroissant",
  FiletypeAscend: "Type de fichier - Croissant",
  FiletypeDescend: "Type de fichier - Décroissant",
  StartDateAscend: "Date de début - Croissant",
  StartDateDescend: "Date de début - Décroissant",
  EndDateAscend: "Date de fin - Croissant",
  EndDateDescend: "Date de fin - Décroissant",
  UploadedAscend: "Date de creation - Croissant",
  UploadedDescend: "Date de creation - Decroissant",
};

export default fr;
