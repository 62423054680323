import React, { useState } from "react";
import { Button, Col, Form, Input, message, Row } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { injectIntl, FormattedMessage } from "react-intl";
import { useFetchWithMsal } from "../hooks";

function UploadCreativeUrlCard({ onSave, intl }) {
  const { fetch, loading } = useFetchWithMsal();
  const [form] = Form.useForm();
  const [checker, setChecker] = useState(false);

  const uploadUrl = async (values) => {
    const formData = new FormData();

    formData.append(`client`, values.client || "");
    formData.append(`annonceur`, values.annonceur || "");
    formData.append(`title`, values.title || "");
    formData.append(`url`, values.url || "");

    try {
      const responses = await fetch(`creatives/upload`, {
        method: "POST",
        body: formData,
      });

      onSave(responses);
      form.resetFields();
      message.success("Création ou créations importées avec succès");
    } catch (error) {
      console.log(error);
      message.error(
        "Une erreur s'est produite lors du téléchargement de la création"
      );
    }
  };

  const validateUrl = (value) => {
    try {
      new URL(value);
    } catch (_) {
      return false;
    }
    return true;
  };

  const validateForm = () => {
    return setChecker(
      form.isFieldTouched("title") && validateUrl(form.getFieldValue("url"))
    );
  };

  return (
    <Row>
      <Col span={18}>
        <Form
          form={form}
          onFinish={uploadUrl}
          onChange={validateForm}
          name="urlForm"
        >
          <Form.Item
            name="url"
            rules={[
              {
                message: intl.formatMessage({ id: "ValidUrl" }),
                validator: (_, value) => {
                  try {
                    new URL(value);
                  } catch (_) {
                    return Promise.reject("Error");
                  }
                  return Promise.resolve();
                },
              },
              {
                required: true,
                message: false,
              },
            ]}
            label="Url"
          >
            <Input addonBefore="Url" />
          </Form.Item>
          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "ValidTitle" }),
              },
            ]}
            label={intl.formatMessage({ id: "Title" })}
          >
            <Input addonBefore={intl.formatMessage({ id: "Title" })} />
          </Form.Item>
          <Form.Item name="client" label={intl.formatMessage({ id: "Client" })}>
            <Input addonBefore={intl.formatMessage({ id: "Client" })} />
          </Form.Item>
          <Form.Item
            name="annonceur"
            label={intl.formatMessage({ id: "Annonceur" })}
          >
            <Input addonBefore={intl.formatMessage({ id: "Annonceur" })} />
          </Form.Item>

          <Button
            type="primary"
            loading={loading}
            icon={<UploadOutlined />}
            htmlType="submit"
            disabled={!checker}
          >
            <FormattedMessage id="UploadUrl" />
          </Button>
        </Form>
      </Col>
    </Row>
  );
}

export default injectIntl(UploadCreativeUrlCard);
