import React, { useState } from "react";
import { Button, Card, Col, Form, Input, message, Row, Upload } from "antd";
import { FileJpgOutlined } from "@ant-design/icons";
import { injectIntl, FormattedMessage } from "react-intl";
import { useFetchWithMsal } from "../hooks";

function FileCard({ file, files, setFiles }) {
  const [src, setSrc] = useState("#");

  const reader = new window.FileReader();
  reader.onload = function () {
    const dataURL = reader.result;

    setSrc(dataURL);
  };
  reader.readAsDataURL(file);

  function handleChange(_, allValues) {
    const newFiles = files.map((fl) => {
      if (fl.name === file.name) {
        fl.form = allValues;
      }
      return fl;
    });

    setFiles(newFiles);
  }

  return (
    <Card style={{ marginTop: "10px" }}>
      <Row gutter={8}>
        <Col span={6}>
          {src.includes("video/mp4") ? (
            <video width="100%">
              <source src={src} />
            </video>
          ) : (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img src={src} id={file.name} style={{ width: "100%" }} />
          )}
        </Col>
        <Col span={18}>
          <Form size="" onValuesChange={handleChange}>
            <Form.Item name="title" label="Title" required>
              <Input addonBefore="Title" />
            </Form.Item>
            <Form.Item name="client" label="Client">
              <Input addonBefore="Client" />
            </Form.Item>
            <Form.Item name="annonceur" label="Annonceur">
              <Input addonBefore="Annonceur" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  );
}

function UploadCreativeFileCard({ onSave, action, files = [], setFiles }) {
  const { fetch, loading } = useFetchWithMsal();

  const checker = !files.every((file) => {
    return file.form && file.form.title;
  });

  function beforeUpload(file) {
    setFiles((s) => [...s, file]);
    return false;
  }

  async function onFinish() {
    const formData = new FormData();

    files.forEach((file, idx) => {
      formData.append(`file-${idx}`, file, file.name);
      formData.append(`client`, file.form.client || "");
      formData.append(`annonceur`, file.form.annonceur || "");
      formData.append(`title`, file.form.title);
    });

    try {
      const data = await fetch(`creatives/upload`, {
        method: "POST",
        body: formData,
      });

      onSave(data);
      message.success("Création ou créations importées avec succès");
      setFiles([]);
    } catch (error) {
      console.log(error);
      message.error(
        "Une erreur s'est produite lors du téléchargement de la création"
      );
    }
  }

  return (
    <>
      <Upload.Dragger
        style={{ width: "100%" }}
        name="file"
        multiple
        showUploadList={false}
        beforeUpload={beforeUpload}
        action={action}
      >
        <p className="ant-upload-drag-icon">
          <FileJpgOutlined />
        </p>
        <p className="ant-upload-text">
          <FormattedMessage id="DragSchedule" />
        </p>
        <p className="ant-upload-hint">
          <FormattedMessage id="ClickBrowserFile" />
        </p>
      </Upload.Dragger>
      <Row style={{ paddingTop: "10px" }}>
        <Col span={24}>
          {files.length > 0 &&
            files.map((file) => (
              <FileCard
                key={file.uid}
                file={file}
                onSave={onSave}
                files={files}
                setFiles={setFiles}
              />
            ))}
        </Col>
      </Row>
      {files.length > 0 && (
        <Button
          type="primary"
          onClick={onFinish}
          loading={loading}
          style={{ marginTop: "5px" }}
          disabled={checker}
        >
          <FormattedMessage id="Save" />
        </Button>
      )}
    </>
  );
}

export default injectIntl(UploadCreativeFileCard);
