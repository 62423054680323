import React, { useState } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Button, Col, Row, Table, Upload, message } from "antd";
import { FormattedMessage } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useFetchWithMsal } from "../hooks";
import PlayFiles from "../components/PlayFiles";

function Play() {
  const [files, setFiles] = useState([]);
  const { fetch, loading } = useFetchWithMsal();

  const columns = [
    {
      title: <FormattedMessage id="Date" />,
      dataIndex: "lastModifiedDate",
      render: (date) => `${moment(date).format("YYYY-MM-DD HH:mm")}`,
      key: "lastModifiedDate",
    },
    {
      title: <FormattedMessage id="Name" />,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <FormattedMessage id="FileType" />,
      dataIndex: "type",
      render: (type) => `${type === "application/zip" ? "Tags" : "Annexe"}`,
      key: "type",
    },
    {
      title: <FormattedMessage id="Status" />,
      dataIndex: "status",
      render: (status) => (
        <div style={{ textAlign: "center" }}>
          {status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
        </div>
      ),
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => deleteFiles(record)}>
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  function handleFile(file) {
    file.status = files.length <= 2 ? true : false;
    file.key = file.uid;
    const fileVerify = file.name.includes("xlsx") || file.name.includes("zip");
    fileVerify
      ? setFiles((s) => [...s, file])
      : message.error("Veuillez télécharger un type de fichier valide !");

    return false;
  }

  async function deleteFiles(record) {
    return setFiles(files.filter((f) => f.key !== record.key));
  }

  async function handleUpload() {
    const formData = new FormData();

    files.forEach((file, idx) => {
      formData.append(`file${idx}`, file, file.name);
    });

    try {
      await fetch("play/files", {
        method: "POST",
        body: formData,
      });
      setFiles([]);
      message.success("Lire les fichiers téléchargés avec succès");
    } catch (error) {
      console.log(error);
      message.error(
        "Une erreur s'est produite lors du téléchargement des fichiers Play"
      );
    }
  }

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <Row>
        <Col span={14}>
          <PlayFiles loading={loading} />
        </Col>
        <Col span={10}>
          <div>
            <Upload.Dragger
              name="file"
              style={{ width: "95%" }}
              multiple
              showUploadList={false}
              beforeUpload={handleFile}
            >
              <p className="ant-upload-text">
                <FormattedMessage id="DragSchedule" />
              </p>
              <p className="ant-upload-hint">
                <FormattedMessage id="ClickBrowserFile" />
              </p>
            </Upload.Dragger>
          </div>
          <Row style={{ marginTop: "20px" }}>
            <Table
              size="small"
              style={{ width: "95%" }}
              bordered
              loading={loading}
              columns={columns}
              dataSource={files}
              pagination={true}
            />
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Button
              loading={loading}
              onClick={handleUpload}
              type="dashed"
              disabled={files.length !== 2}
              icon={<UploadOutlined />}
            >
              <FormattedMessage id="UploadFiles" />
            </Button>
          </Row>
        </Col>
      </Row>
    </MsalAuthenticationTemplate>
  );
}

export default Play;
